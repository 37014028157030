<template>
  <page-layout class="survey">
    <h1>{{ $t("survey_results.title") }}</h1>

    <div v-if="hasLoadingError" class="loading-error">
      <p>{{ $t("survey_results.not_loaded_error") }}</p>
    </div>

    <div v-else-if="isLoaded" class="survey-results">
      <h2>{{ componentName }}</h2>

      <div class="survey-result-progress">
        <survey-progress-bar :steps="surveyResults.length" v-model="currentQuestion"></survey-progress-bar>
      </div>

      <survey-carousel v-model="currentQuestion">
        <survey-question v-for="question of surveyResults" :key="question.question_id" :question="question"></survey-question>
      </survey-carousel>

      <div></div>
    </div>

    <div v-else class="page-loader">
      <spinner></spinner>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/web/components/external/ExternalPageLayout";
import SurveyProgressBar from "@/web/components/external/survey/SurveyProgressBar";
import SurveyCarousel from "@/web/components/external/survey/SurveyCarousel";
import SurveyQuestion from "@/web/components/external/survey/SurveyQuestion";
import Spinner from "@/web/components/external/Spinner";
import { mapActions, mapGetters, mapState} from "vuex";

export default {
  components: {
    PageLayout,
    SurveyProgressBar,
    SurveyCarousel,
    SurveyQuestion,
    Spinner
  },

  data() {
    return {
      surveyResultsUUID: null,
      currentQuestion: 1
    };
  },

  mounted() {
    const urlSegments = window.location.pathname.split("/").filter(s => !!s);
    this.surveyResultsUUID = urlSegments[2];

    this.loadSurveyResults(this.surveyResultsUUID);
  },

  destroyed() {
    clearTimeout(this.timeoutId);
  },

  methods: {
    ...mapActions("surveyResults", ["loadSurvey"]),

    onCarouselChange(currentSlide) {
      this.currentQuestion = currentSlide;
    },

    loadSurveyResults(uuid) {
      return this.loadSurvey(uuid).finally(() => this.continueLoading(uuid));
    },

    continueLoading(uuid) {
      this.timeoutId = setTimeout(() => this.loadSurveyResults(uuid), 2500);
    }
  },

  computed: {
    ...mapState("surveyResults", ["surveyResults", "componentName"]),
    ...mapGetters("surveyResults", ["isLoaded", "hasLoadingError"])
  }
};
</script>

<style lang="scss" scoped>
.survey h1 {
  margin: 0 0 10px 0;
}

.survey h2 {
  margin: 0 0 40px 0;
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.survey-results {
  margin-bottom: 20px;
  flex: 1 1;
}

.survey-result-progress {
  padding: 0 40px;
}
</style>

<template>
  <div class="survey-carousel">
    <div class="survey-carousel-prev" @click="prevSlide()" v-if="prevArrowVisible">
      <div class="survey-carousel-arrow" :class="{ animate: prevArrowAnimate }" @animationend="prevArrowAnimate = false">
        <img src="@/assets/external/carousel-prev.png" />
      </div>
    </div>

    <div class="survey-carousel-items">
      <div class="survey-carousel-scroll" :style="scrollStyle">
        <carousel-wrapper><slot></slot></carousel-wrapper>
      </div>
    </div>

    <div class="survey-carousel-next" @click="nextSlide()" v-if="nextArrowVisible">
      <div class="survey-carousel-arrow" :class="{ animate: nextArrowAnimate }" @animationend="nextArrowAnimate = false">
        <img src="@/assets/external/carousel-next.png" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

const CarouselWrapper = Vue.component("wrapper", {
  functional: true,

  render(h, { children }) {
    const wrapped = children.filter(child => !child.text).map(child => h("div", { staticClass: "survey-carousel-item" }, [child]));
    return wrapped;
  }
});

export default {
  components: {
    CarouselWrapper
  },

  props: ["value"],

  data() {
    return {
      prevArrowAnimate: false,
      nextArrowAnimate: false
    };
  },

  mounted() {
    this.keyListener = ev => {
      if (ev.key === "ArrowRight") {
        this.nextSlide();
      } else if (ev.key === "ArrowLeft") {
        this.prevSlide();
      }
    };

    window.addEventListener("keydown", this.keyListener);
  },

  destroyed() {
    window.removeEventListener("keydown", this.keyListener);
  },

  methods: {
    prevSlide() {
      const nextValue = Math.max(this.value - 1, 1);
      this.resetAnimation("prevArrowAnimate");
      this.scrollToBeginIfNeed();

      this.$emit("input", nextValue);
    },

    nextSlide() {
      const nextValue = Math.min(this.value + 1, this.maxSlides);
      this.resetAnimation("nextArrowAnimate");
      this.scrollToBeginIfNeed();

      this.$emit("input", nextValue);
    },

    resetAnimation(propName) {
      this[propName] = false;
      setTimeout(() => (this[propName] = true));
    },

    scrollToBeginIfNeed() {
      const scrollTop = document.documentElement.scrollTop;
      const hostBounds = this.$el.getBoundingClientRect();

      if (hostBounds.top < 0) {
        this.scrollTo(scrollTop + hostBounds.top - 20);
      }
    },

    scrollTo(height) {
      document.documentElement.scrollTop = height;
    }
  },

  computed: {
    prevArrowVisible() {
      return this.value > 1;
    },

    nextArrowVisible() {
      return this.value < this.maxSlides;
    },

    maxSlides() {
      const filteredSlot = this.$slots.default.filter(child => !child.text);
      return filteredSlot.length;
    },

    scrollStyle() {
      const index = this.value - 1;
      const leftTransform = index * 100.0;

      return {
        // use translate3D to force hardware rendering
        transform: `translate3D(-${leftTransform}%, 0%, 1px)`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.survey-carousel {
  display: flex;
}

.survey-carousel-prev,
.survey-carousel-next {
  flex: 0 0 60px;
  user-select: none;
  cursor: pointer;

  @media (max-width: 767px) {
    flex-basis: 30px;
  }
}

.survey-carousel-arrow {
  display: flex;
  height: 100%;
  max-height: 50vh;
  align-items: center;
  position: sticky;
  top: 25vh;

  .survey-carousel-next > & {
    justify-content: flex-end;
  }

  .survey-carousel-next > &.animate > img {
    animation: nextArrowBounce 300ms ease;
  }

  .survey-carousel-prev > &.animate > img {
    animation: prevArrowBounce 300ms ease;
  }

  > img {
    margin: 0 10px;
    position: relative;

    @media (max-width: 767px) {
      transform: scale(0.75);
    }
  }
}

.survey-carousel-items {
  flex-grow: 1;
  overflow: hidden;
  position: relative;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 16px;
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
  }

  &:before {
    background: linear-gradient(left, #fff, rgba(255, 255, 255, 0));
    left: 0px;
  }

  &:after {
    background: linear-gradient(right, #fff, rgba(255, 255, 255, 0));
    right: 0px;
  }
}

.survey-carousel-scroll {
  display: flex;
  transition: transform 400ms ease;
}

.survey-carousel-item {
  padding: 0 20px;
  flex: 0 0 100%;
  width: 100%;
}

@keyframes prevArrowBounce {
  0% {
    left: 0px;
  }
  50% {
    left: -8px;
  }
  100% {
    left: 0px;
  }
}

@keyframes nextArrowBounce {
  0% {
    left: 0px;
  }
  50% {
    left: 8px;
  }
  100% {
    left: 0px;
  }
}
</style>
